@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family:
      Inter var,
      system-ui,
      sans-serif;
  }
}
